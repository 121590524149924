import { useEffect, useState } from "react";
import {
  Icon,
} from 'semantic-ui-react';

import * as ApiModels from '../support/apiModels';
import { canPlayVideo, makeCancelable } from '../support/helpers';
import Loading from "./Loading";
import Thumbnail from './Thumbnail';

const exampleIds = [
  "7beb7076-8c83-47b6-b6a4-ffe3386de57d", // Tie Fighter
  "1299e276-d0f6-40c6-b17f-de783d290903", // Cabin Cruiser
  "bff85d3f-4b59-4421-aa63-9e6a8c58c89f", // Passenger Train Car
  "42e46861-e5dd-4260-8572-8b5fa40f046a", // Golden Egg
  "u71xHqjEtWBgc8j1LmEy2L", // Question Block
  "1657bbae-df8c-46ea-86d6-5cce6c97a503", // Schooner
  "r2x7JCp8W7AMgsZFmjNHhZ", // Brick Block
  "aaf4bd3f-d2bd-4d4e-8057-9b2ef6dc228c", // Fighter Jet
  "wfQuJZyBkwtDjm1JiTsnu4", // Game Boy
  "eXpU5TMjCQm4tMc2taLQH9", // Boeing 747
  "meFEY8XJ3EvzmvqGZWgt9R", // Minecraft Pickaxe
  "9e3b0838-729d-4c6d-bc37-9d9f5c369fe2", // Eiffel Tower
];

interface Props {
  heading?: string,
  filter?: (creation: ApiModels.Creation) => boolean,
  onSelect: (creationId: string) => void,
}

function Examples(props: Props) {

  const [examples, setExamples] = useState<ApiModels.Creation[] | undefined>();
  const [exampleOpening, setExampleOpening] = useState<ApiModels.Creation | undefined>();

  useEffect(() => {
    async function run() {
      const query = new URLSearchParams(exampleIds.map(ea => ["examples", ea]));
      const response = await fetch(`/api/examples?${query}`).then(res => res.json());
      let examplesToUse = response.examples;
      if (props.filter) {
        examplesToUse = examplesToUse.filter(props.filter);
      }
      setExamples(examplesToUse);
    }
    const cancelable = makeCancelable(run());
    return () => {
      cancelable.cancel();
    }
  }, []);

  function openExample(creation: ApiModels.Creation) {
    setExampleOpening(creation);
    // hack to avoid aggressive browser caching leaving the state this way when user goes back
    setTimeout(() => {
      setExampleOpening(undefined);
    }, 2000);
    props.onSelect(creation.creationId);
  }

  function renderExample(creation: ApiModels.Creation, canPlay: boolean) {
    return (
      <div className="thumbnail" key={`creation-${creation.creationId}`}>
        <a onClick={() => openExample(creation)}>
          <Thumbnail gifUrl={creation.thumbnailGifUrl} videoUrl={creation.thumbnailVideoUrl} canPlayVideo={canPlay} />
        </a>
        <span className="name">{creation.name}</span>
      </div>
    );
  }

  function renderHeading() {
    if (props.heading) {
      return <div className="header examples">{props.heading}</div>
    }
  }

  function renderOpeningExample() {
    const msg = exampleOpening ? `Opening ${exampleOpening.name}` : undefined;
    return (
      <Loading loadingMessage={msg} />
    )
  }

  function renderExamples() {
    if (examples) {
      const canPlay = canPlayVideo();
      return (
        <div>
          {renderOpeningExample()}
          {renderHeading()}
          <div className="examples creations-grid">
            {examples.map(ea => renderExample(ea, canPlay))}
          </div>
        </div>
      )
    } else {
      return (
        <div className="header examples">
          <Icon name="spinner" loading />
          <span>Loading examples</span>
        </div>
      );
    }
  }

  return renderExamples();

}

export default Examples;