import { useEffect, useRef, useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import Player, { Options } from '@vimeo/player';

import { makeCancelable } from '../support/helpers';

interface Props {
  videoIDs: number[],
  startIndex?: number,
  initialHeading?: string,
}

export default function DemoVideos(props: Props) {

  const { startIndex, initialHeading, videoIDs } = props;

  const [player, setPlayer] = useState<Player | undefined>();
  const [videoIndex, setVideoIndex] = useState<number>(startIndex || 0);
  const [currentVideoTitle, setCurrentVideoTitle] = useState<string>('');
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);

  const playerRef = useRef<HTMLDivElement>(null);

  function changeVideoIndex(newIndex: number) {
    setCurrentVideoTitle('');
    setIsVideoPlaying(false);
    setVideoIndex(newIndex);
  }

  function goToNextVideo() {
    const newIndex = (videoIndex + 1) % videoIDs.length;
    changeVideoIndex(newIndex);
  }

  function goToPreviousVideo() {
    const newIndex = videoIndex === 0 ? videoIDs.length - 1 : videoIndex - 1;
    changeVideoIndex(newIndex);
  }

  useEffect(() => {

    function videoOptions(): Options {
      const isProbablyMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
      return {
        id: videoIDs[videoIndex],
        responsive: true,
        title: true,
        playsinline: !isProbablyMobile,
        pip: false,
        quality: 'auto',
      };
    }

    async function onUpdatedVideo(player: Player) {
      setCurrentVideoTitle(await player.getVideoTitle());
      setIsVideoPlaying(false);
      await player.exitFullscreen();
      player.off('play');
      player.on('play', () => {
        player.off('ended');
        player.on('ended', goToNextVideo);
      });
      player.off('progress');
      player.on('progress', () => {
        setIsVideoPlaying(true);
      });
      player.off('pause');
      player.on('pause', () => {
        setIsVideoPlaying(false);
      });
    }

    async function run() {
      if (player) {
        const currentID = await player.getVideoId();
        if (currentID !== videoIDs[videoIndex]) {
          await player.unload();
          await player.loadVideo(videoOptions());
          await onUpdatedVideo(player);
        }
      } else if (playerRef.current && player === undefined) {
        const newPlayer = new Player(playerRef.current, videoOptions());
        setPlayer(newPlayer);
        newPlayer.setVolume(0.5);
        await onUpdatedVideo(newPlayer);
      }
    }

    const cancelable = makeCancelable(run());
    return () => {
      cancelable.cancel();
    }
  }, [playerRef, player, videoIndex]);

  function videoTitle(): React.ReactNode {
    if (initialHeading && videoIndex === 0) {
      return initialHeading;
    } else if (currentVideoTitle) {
      return `${isVideoPlaying ? "Now playing" : "Next"}: ${currentVideoTitle}`;
    } else {
      return <Icon name="spinner" loading />;
    }
  }

  function togglePlay() {
    if (player) {
      if (isVideoPlaying) {
        player.pause();
      } else {
        player.play();
      }
    }
  }

  return (
    <div className="demo-videos">
      <div className="header dark">
        <Button className="nav left" compact icon="step backward" onClick={goToPreviousVideo} />
        <span onClick={togglePlay}>{videoTitle()}</span>
        <Button className="nav right" compact icon="step forward" onClick={goToNextVideo} />
      </div>
      <div className="demo-video-container">
        <div ref={playerRef}></div>
      </div>
    </div>
  );

}