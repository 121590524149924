import { useEffect, useState } from 'react';
import { usePlausible } from 'next-plausible'

import Head from 'next/head';
import 'url-search-params-polyfill';

import { ensurePosthogForApiClient } from '../support/ensurePosthog';
import { allVideos } from '../support/videoSets';

import EmailSignInForm from '../pcomponents/EmailSignInForm';
import Examples from '../pcomponents/Examples';

enum TrackEvent {
  EmailSubscribe = 'email-subscribe',
}

import {
  Button,
  Icon,
} from 'semantic-ui-react';

import { ApiClient } from '../support/api';

import DemoVideos from '../pcomponents/DemoVideos';
import Logo from '../pcomponents/Logo';
import WithAuth from '../pcomponents/WithAuth';

interface Props {
  apiBaseUrl: string,
  taglines: React.ReactNode[],
}

function LandingPage(props: Props) {

  const plausible = usePlausible();

  const [apiClient, setApiClient] = useState<ApiClient>(ApiClient.beforeAuthCheck(props.apiBaseUrl));
  const [emailInputValue, setEmailInputValue] = useState<string>("");
  const [passwordInputValue, setPasswordInputValue] = useState<string>("");
  const [emailSentTo, setEmailSentTo] = useState<string | undefined>();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isRequestingPassword, setIsRequestingPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactNode | undefined>();

  useEffect(() => {
    ensurePosthogForApiClient(apiClient);
  }, [apiClient]);

  function onAuthStateChanged(apiClient: ApiClient) {
    setApiClient(apiClient);
  }

  function renderContactInfo() {
    return (
      <div className="contact-info">
        <span>Questions or feedback? </span>&nbsp;<a target="_blank" href="mailto:hello@makeandplay.com" >
          <Icon name="mail" size="large" />
        </a>&nbsp;<a target="_blank" href="https://twitter.com/MakeAndPlayHQ">
          <Icon name="twitter" size="large" />
        </a>&nbsp;<a target="_blank" href="https://instagram.com/MakeAndPlayHQ">
          <Icon name="instagram" size="large" />
        </a>
      </div>
    );
  }

  function formPrompt() {
    if (isRequestingPassword) {
      return "Sign in with email and password:";
    } else {
      return "Sign up or sign in:";
    }
  }

  function renderCTA() {
    if (apiClient.hasCheckedAuth()) {
      if (apiClient.isLoggedIn()) {
        return (
          <div className="action">
            <Button
              className="get-started"
              fluid
              icon="right arrow"
              labelPosition="right"
              size="huge"
              content="Go to your creations"
              onClick={() => window.location.href = "/creations"}
            />
          </div>
        );
      } else {
        return (
          <EmailSignInForm
            emailInputValue={emailInputValue}
            onUpdateEmailInputValue={setEmailInputValue}
            emailSentTo={emailSentTo}
            onUpdateEmailSentTo={setEmailSentTo}
            errorMessage={errorMessage}
            onUpdateErrorMessage={setErrorMessage}
            isSending={isSending}
            onUpdateIsSending={setIsSending}
            passwordInputValue={passwordInputValue}
            onUpdatePasswordInputValue={setPasswordInputValue}
            isRequestingPassword={isRequestingPassword}
            onUpdateIsRequestingPassword={setIsRequestingPassword}
            prompt={formPrompt()}
            onSend={() => plausible(TrackEvent.EmailSubscribe)}
          />
        );
      }
    }
  }

  function renderContent() {
    return (
      <div>
        <Head>
          <title>Welcome to Make and Play</title>
        </Head>
        <div className="intro">
          <div className="intro-header">
            <Logo />
          </div>
          <div className="intro-columns">
            <div className="intro-column">
              {props.taglines}
              {renderCTA()}
            </div>
            <div className="intro-column video">
              <DemoVideos
                initialHeading="See it in action:"
                videoIDs={allVideos}
              />
              {renderCTA()}
              {renderContactInfo()}
              <Examples
                heading="Check out some examples:"
                onSelect={(creationId: string) => window.location.href = `/c/${creationId}`}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <WithAuth
      apiClient={apiClient}
      onAuthStateChanged={onAuthStateChanged}
      isSignInOptional={true}
    >
      {renderContent()}
    </WithAuth>
  );

}

export default LandingPage;