import { useEffect, useRef } from 'react';

interface Props {
  gifUrl: string | undefined,
  videoUrl: string | undefined,
  canPlayVideo: boolean,
}

function Thumbnail(props: Props) {

  const videoRef = useRef<HTMLVideoElement>(null);

  // Needed due to issue in react where these attributes don't appear in the DOM
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.setAttribute('muted', 'true');
      videoRef.current.setAttribute('playsinline', 'true');
    }
  }, [videoRef.current]);

  function renderVideo(src: string) {
    return (
      <video ref={videoRef} autoPlay loop muted>
        <source src={src} type="video/mp4" />
      </video>
    );
  }

  function renderGif(src: string) {
    return (
      <img src={src} />
    );
  }

  if (props.canPlayVideo) {
    if (props.videoUrl) {
      return renderVideo(props.videoUrl);
    } else if (props.gifUrl) {
      return renderGif(props.gifUrl);
    } else {
      return renderVideo("/images/defaultThumbnail.mp4");
    }
  } else {
    return renderGif(props.gifUrl || "/images/defaultThumbnail.gif");
  }
}

export default Thumbnail;