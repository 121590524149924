import posthog from 'posthog-js'

import { ApiClient } from "./api";
import * as ApiModels from './apiModels';

export function ensurePosthogForUser(user: ApiModels.User | undefined) {
  if (process.env.NEXT_PUBLIC_POSTHOG_PUBLISHABLE_KEY && process.env.NEXT_PUBLIC_POSTHOG_API_HOST) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_PUBLISHABLE_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST,
      loaded: posthog => {
        if (user) {
          posthog.identify(user.id, { email: user.email });
        }
      }
    });
    return posthog;
  }
}

export function ensurePosthogForApiClient(apiClient: ApiClient) {
  if (process.env.NEXT_PUBLIC_POSTHOG_PUBLISHABLE_KEY && process.env.NEXT_PUBLIC_POSTHOG_API_HOST && apiClient.hasCheckedAuth()) {
    return ensurePosthogForUser(apiClient.user);
  }
}