export const introVideos = [
  633100206,
  717085955,
];

export const howToVideos = [
  633102360,
  634155807,
  702359010,
];

export const allVideos = introVideos.concat(howToVideos);