import {
  Dimmer,
  Loader
} from 'semantic-ui-react';

interface Props {
  loadingMessage: string | undefined,
  imageRef?: React.RefObject<HTMLImageElement>
}

export default function Loading(props: Props) {

  function renderLoadingContent() {
    return (
      <div className="loading-content">
        <div>
          <img height="100px" ref={props.imageRef} />
        </div>
        <div>{props.loadingMessage}</div>
      </div>
    );
  }

  return (
    <Dimmer active={!!props.loadingMessage} page>
      <Loader>{renderLoadingContent()}</Loader>
    </Dimmer>
  )

}